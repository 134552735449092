import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationCategoryComponent } from './location-category.component';
import { ListLocationCategoryComponent } from './list-location-category/list-location-category.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BlockUIModule } from 'ng-block-ui';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { RightLocationCategoryComponent } from './right-location-category/right-location-category.component';
import { CoreSidebarModule } from '@core/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreCommonModule } from '@core/common.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'app/enum/role';
import { CsvModule } from '@ctrl/ngx-csv';
const routes: Routes = [
  {
    path: 'location-category',
    component: LocationCategoryComponent,
    canActivate:[AuthGuard],
    data: {roles:[Role.Admin,Role.Supervisor], animation: 'auth' },
  },
];

@NgModule({
  declarations: [
    LocationCategoryComponent,
    ListLocationCategoryComponent,
    RightLocationCategoryComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    BlockUIModule.forRoot(),
    ContentHeaderModule,
    CoreSidebarModule,
    NgSelectModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    NgbModule,
    CsvModule,
    
  ]
})
export class LocationCategoryModule { }
