<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-lg-4" *ngFor="let item of getAllEndpoint">
            <div class="card mb-4">
              <div class="card-header d-flex justify-content-between align-items-center">

                <h4 class="card-title">{{item.name}}</h4>
                <div class="avatar bg-primary bg-darken-2">
                  <div class="avatar-content">{{item.actions.length}}</div>
                </div>
              </div>
              <div class="card-body">


                <ul class="list-group list-group-flush" *ngFor="let action of item.actions">
                  <p>

                    <br>
                    {{ translate(item.name,action.definition) }}

                    <button type="button" class="btn btn-icon btn-flat-success" rippleEffect
                      (click)="addEvent(modalSM,item.name,action.code)">
                      <span [data-feather]="'plus'"></span></button>
                  </p>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalSM let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{menu}} <sub>{{code}}</sub></h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="custom-control custom-checkbox" *ngFor="let action of data">
      <input type="checkbox" class="custom-control-input" id="{{action.id}}" [checked]="action.checked"
        (change)="toggleCheckbox(action)" />
      <label class="custom-control-label" for="{{action.id}}">{{action.name}}</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Success')" rippleEffect>
      Kaydet
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')">İptal</button>
  </div>
</ng-template>