<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section class="users-list-wrapper">
      <div class="card">
        <div class="row">
          <div class="col-12 mb-50">
            <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-user-sidebar')">
              <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                class="d-none d-sm-inline-block">Yeni Kullanıcı Ekle</span>
            </button>
          </div>
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Gösterilecek satır
                <select class="form-control mx-25" [(ngModel)]="basicSelectedOption" (ngModelChange)="onOptionChange()">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="50">50</option>
                </select>
                sayısı
              </label>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
              <label class="d-flex align-items-center">Bul<input type="search" placeholder="Bul"
                  class="form-control ml-25" (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" /></label>
            </div>
          </div>
        </div>
        <ngx-datatable [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [limit]="10"
          [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">
          <ngx-datatable-column name="Sicil No" prop="userName"></ngx-datatable-column>
          <ngx-datatable-column name="Adı Soyadı" prop="userNameOrLastName"></ngx-datatable-column>
          <ngx-datatable-column name="Yetki" prop="role">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <ng-container *ngIf="row.role && row.role.length > 0; else noRole">
                {{ row.role.join(', ') }}
              </ng-container>
              <ng-template #noRole>
                Yok
              </ng-template>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Hareket Sayısı" prop="movementCount"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div ngbDropdown container="body">
                <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow" rippleEffect>
                  <span [data-feather]="'more-vertical'" [class]="'cursor-pointer'"></span>
                </button>
                <div ngbDropdownMenu *ngIf="row.id! != 1 || row.id == userId">
                  <a (click)="updateLocationCategory(row,modalForm)" ngbDropdownItem
                    class="d-flex align-items-center"><i data-feather="file-text" class="mr-50"></i>Güncelle</a>
                  <!-- <a href="javascript:;" ngbDropdownItem
                      ><span [data-feather]="'trash'" [size]="16" [class]="'mr-50'"></span> Delete</a
                    > -->
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
                <div class="datatable-footer-inner">
                    <div class="page-count">
                      Toplam Veri {{ totalCount }}
                    </div>
        
        
                    <ngb-pagination [collectionSize]="(totalCount / basicSelectedOption) * 10" [(page)]="pageBasic"
                        (pageChange)="onPageChange($event)">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18"
                                [class]="'font-weight-bolder'"></span>
                        </ng-template>
                        <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18"
                                [class]="'font-weight-bolder'"></span></ng-template>
                    </ngb-pagination>
                </div>
        
            </ng-template>
        
        </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </section>
    <!--/ User List -->
  </div>

  <!-- New User Sidebar -->
  <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-user-sidebar"
    overlayClass="modal-backdrop">
    <app-new-user-sidebar></app-new-user-sidebar>
  </core-sidebar>
  <!--/ New User Sidebar -->
</div>

<ng-template #modalForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Kullanıcı Güncelleme</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form action="#">
      <label>UserName</label>
      <div class="form-group">
        <input type="text" id="userName" name="userName" [(ngModel)]="userName"
          placeholder="Güncellemek istediğiniz kullanıcı adını giriniz." class="form-control" />
      </div>
      <label>Adı Ve Soyadı</label>
      <div class="form-group">
        <input type="text" id="userNameAndLastName" name="userNameAndLastName" [(ngModel)]="userNameAndLastName"
          placeholder="Güncellemek istediğiniz adı ve soyadını giriniz." class="form-control" />
      </div>
      <label>Şifre</label>
      <div class="form-group">
        <input type="text" id="userPassword" name="userPassword" [(ngModel)]="userPassword"
          placeholder="Güncellemek istediğiniz şifreyi giriniz." class="form-control" />
      </div>

      <label *ngIf="authService.isRole === 'admin'" for="basicSelect">Roller</label>
      <div class="form-group">
        <ng-select *ngIf="authService.isRole === 'admin'" [items]="selectRole" [searchable]="false" bindLabel="name"
          placeholder="Rol seçiniz" [(ngModel)]="selectedRole" name="selectedRole">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ item.name }}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </ng-template>
        </ng-select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Cancel')" rippleEffect>
      İptal
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Success')" rippleEffect>
      Güncelle
    </button>
    <button type="button" class="btn btn-primary" *ngIf="authService.isRole === 'admin'" (click)="modal.close('Roles')" rippleEffect>
      Rol Güncelle
    </button>
  </div>
</ng-template>
