<div class="slideout-content">
  <!-- Modal to add new user starts-->
  <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <form class="add-new-user modal-content pt-0" (ngSubmit)="(newUserForm.form.valid)" #newUserForm="ngForm">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('new-user-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title" id="exampleModalLabel">Yeni kullanıcı</h5>
        </div>
        <div class="modal-body flex-grow-1">
          <div class="form-group">
            <label class="form-label" for="basic-icon-default-uname">Sicil No(Kullanıcı Adı)</label>
            <input
              type="text"
              id="basic-icon-default-uname"
              class="form-control dt-uname"
              placeholder="Sicil No(Kullanıcı Adı)"
              aria-label="jdoe1"
              aria-describedby="basic-icon-default-uname2"
              name="username"
              [(ngModel)]="username"
              [class.error]="newUserForm.submitted && FUserNameRef.invalid"
              #FUserNameRef="ngModel"
              required
            />
            <span *ngIf="newUserForm.submitted && FUserNameRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FUserNameRef.errors.required">Kullanıcı adı boş olamaz!</small>
            </span>
          </div>

          <div class="form-group">
            <label class="form-label" for="basic-icon-default-fullname">Adı ve Soyadı</label>
            <input
              type="text"
              id="basic-icon-default-fullname"
              class="form-control dt-uname"
              placeholder="Adı ve Soyadı"
              aria-label="jdoe1"
              aria-describedby="basic-icon-default-fullname2"
              name="userNameAndLastName"
              [(ngModel)]="userNameAndLastName"
              [class.error]="newUserForm.submitted && FuserNameAndLastName.invalid"
              #FuserNameAndLastName="ngModel"
              required
            />
            <span *ngIf="newUserForm.submitted && FuserNameAndLastName.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FuserNameAndLastName.errors.required">Adı ve Soyadı boş olamaz!</small>
            </span>
          </div>

          <div class="form-group">
            <label class="form-label" for="basic-icon-default-uname">Şifre</label>
            <div class="input-group input-group-merge form-password-toggle">
            
              <input
              [type]="passwordTextType ? 'text' : 'password'"
              id="basic-icon-default-upassword"
              class="form-control dt-upassword"
              placeholder="Şifre"
              aria-label="jdoe1"
              aria-describedby="basic-icon-default-uname2"
              name="userpassword"
              [(ngModel)]="password"
              [class.error]="FPasswordRef.submitted && FPasswordRef.invalid"
              #FPasswordRef="ngModel"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                    'icon-eye-off': passwordTextType,
                    'icon-eye': !passwordTextType
                  }" (click)="togglePasswordTextType()"></i></span>
            </div>
            </div>
           
            <span *ngIf="newUserForm.submitted && FPasswordRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FPasswordRef.errors.required">Şifre boş olamaz!</small>
            </span>
          </div>

          <!-- <div class="form-group">
            <label class="form-label" for="user-role">User Role</label>
            <select id="user-role" class="form-control">
              <option value="subscriber">Subscriber</option>
              <option value="editor">Editor</option>
              <option value="maintainer">Maintainer</option>
              <option value="author">Author</option>
              <option value="admin">Admin</option>
            </select>
          </div> -->
          <button type="submit" class="btn btn-primary mr-1" (click)="submit(newUserForm)" rippleEffect>Ekle</button>
          <button
            type="reset"
            class="btn btn-outline-secondary"
            (click)="toggleSidebar('new-user-sidebar')"
            rippleEffect
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- Modal to add new user Ends-->
</div>
