<div class="row">
    <div class="col-md-6 col-12">
        <div class="d-flex justify-content-between align-items-center m-1">
            <label class="d-flex align-items-center">Gösterilecek satır
                <select class="form-control mx-25" [(ngModel)]="basicSelectedOption" (ngModelChange)="onOptionChange()">
                    <option value="5">2</option>
                    <option value="10">3</option>
                    <option value="15">4</option>
                    <option value="20">5</option>
                </select>
                sayısı
            </label>
        </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
        <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
            <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                    class="form-control ml-25" (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" /></label>
        </div>
    </div>
</div>
<section id="ngx-datatable-Responsive">
    <ngx-datatable class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="40" [rowHeight]="58"
        [footerHeight]="50" [count]="totalCount" [limit]="basicSelectedOption" rowHeight="auto" [rows]="rows"
        [scrollbarH]="true" *blockUI="'section-block'">
        <ngx-datatable-column name="Id" prop="id"></ngx-datatable-column>
        <ngx-datatable-column name="Title" prop="name"></ngx-datatable-column>
        <ngx-datatable-column name="Count" prop="categoryCount"></ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
                <div class="datatable-footer-inner">
                    <div class="page-count">
                        Toplam Veri {{ totalCount }}
                    </div>


                    <ngb-pagination [collectionSize]="(totalCount / basicSelectedOption) * 10" [(page)]="pageBasic"
                        (pageChange)="onPageChange($event)">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18"
                                [class]="'font-weight-bolder'"></span>
                        </ng-template>
                        <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18"
                                [class]="'font-weight-bolder'"></span></ng-template>
                    </ngb-pagination>
                </div>

            </ng-template>

        </ngx-datatable-footer>

    </ngx-datatable>
</section>