import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { _isId } from 'app/services/auth.service';
import { LocationSubCategoryService } from 'app/services/models/location-sub-category.service';
import { MovementService } from 'app/services/models/movement.service';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-trasform-dialog',
  templateUrl: './product-trasform-dialog.component.html',
  styleUrls: ['./product-trasform-dialog.component.scss']
})
export class ProductTrasformDialogComponent implements OnInit,AfterViewInit {
  @ViewChild('action', { static: false }) scanner?: NgxScannerQrcodeComponent;
  cameras: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined;
 @Input() data: any;

  constructor(
    public ngbModal:NgbActiveModal,
    private locationSubCategoryService:LocationSubCategoryService,
    private movementService:MovementService,
    private toastService:ToastrService) { }
  ngAfterViewInit(): void {

  }

  readQR = '';
  locationSubCateogy:any;

  ngOnInit(): void {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      this.cameras = devices.filter(device => device.kind === 'videoinput');
      if (this.cameras.length > 0) {
        this.selectedDevice = this.cameras[0];  // Varsayılan olarak ilk kamerayı seç
      }
    });
  }

  async createMovement(){
    const result = await this.movementService.createMovemnt(this.data.id,this.locationSubCateogy.id,_isId)
   
    if(result.status==true){
      this.movementService.sendEvent(true);
      this.toastService.success(result.message);
    }  
    else
      this.toastService.error(result.message);
    this.closeModal();
  }

  closeModal() {
    this.ngbModal.close('Modal Kapatıldı.');
  }

  async handleScanSuccess(event){
    this.readQR = '';
    if(event){
      this.readQR = event[0].value
      this.scanner!.stop();
      this.locationSubCateogy = await this.locationSubCategoryService.getLocationSubCategory(null,this.readQR);
      if(this.locationSubCateogy == null)
        this.toastService.error("Böyle bir konum bulunamadı");
    }
 
  }

  selectCamera(): void {
    if (this.selectedDevice) {
    }
  }
  startScanner(device: MediaDeviceInfo): void {
    if (this.scanner!.isStart) {
      this.scanner.stop();
    }

    // const config: any = {
    //   video: { deviceId: device.deviceId }
    // };
    
    this.scanner!.playDevice(device.deviceId)
  }

  scannerOpen(): void {
    if (this.scanner!.isStart) {
      // Kamerayı durdur
      this.scanner!.stop();
    } else {
      // Kamerayı başlat
      if (this.selectedDevice) {
        this.startScanner(this.selectedDevice);
      
      } else {
      //  this.scanner!.start();
      }
    }
  }

}
