import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';

import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { AuthRegisterV1Component } from './auth-register-v1/auth-register-v1.component';
import { AuthForgotPasswordV1Component } from './auth-forgot-password-v1/auth-forgot-password-v1.component';
import { AuthResetPasswordV1Component } from './auth-reset-password-v1/auth-reset-password-v1.component';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { BlockUIModule } from 'ng-block-ui';

// routing
const routes: Routes = [
  {
    path: 'authentication/login-v2',
    component: AuthLoginV2Component,
    data: { animation: 'auth' },
  },
  /*
  {
    path: 'authentication/register-v1',
    component: AuthRegisterV1Component
  },
  {
    path: 'authentication/forgot-password-v1',
    component: AuthForgotPasswordV1Component
  },
  {
    path: 'authentication/reset-password-v1',
    component: AuthResetPasswordV1Component
  },*/
];

@NgModule({
  declarations: [
    AuthLoginV2Component,
    AuthForgotPasswordV1Component,
    AuthForgotPasswordV1Component,
    AuthRegisterV1Component,
    AuthResetPasswordV1Component
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
    CardSnippetModule,
    BlockUIModule.forRoot()
  ]
})
export class AuthenticationModule {}
