<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex justify-content-between align-items-center m-1">
                    <label class="d-flex align-items-center">Gösterilecek satır
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption" (ngModelChange)="onOptionChange()">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                        sayısı
                    </label>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                 <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center"><input type="search" placeholder="Bul"
                            class="form-control ml-25" (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" /></label>
                </div> 
            </div>
        </div>
        <section id="ngx-datatable-Responsive">
            <ngx-datatable class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="40" [rowHeight]="58"
                [footerHeight]="50" [count]="totalCount" [limit]="basicSelectedOption" rowHeight="auto" [rows]="rows"
                [scrollbarH]="true" >
                <ngx-datatable-column name="Wins Kod" prop="winsName"></ngx-datatable-column>
                <ngx-datatable-column name="Mat No" prop="materialNo"></ngx-datatable-column>
                <ngx-datatable-column name="Tot No" prop="totNo"></ngx-datatable-column>
                <ngx-datatable-column name="Renk Kodu" prop="colorCode"></ngx-datatable-column>
                <ngx-datatable-column name="Batch No" prop="batchNo"></ngx-datatable-column>
                <ngx-datatable-column name="Konum" prop="locationSubCategoryName"></ngx-datatable-column>
                <ngx-datatable-column name="İşlem Tarihi" prop="winsCreatedAt">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.winsCreatedAt | date:'dd.MM.yyyy HH:mm:ss' }}
                    </ng-template>
                  </ngx-datatable-column>
                
        
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>
                        <div class="datatable-footer-inner">
                            <div class="page-count">
                              Toplam Veri {{ totalCount }}
                            </div>
                
                
                            <ngb-pagination [collectionSize]="(totalCount / basicSelectedOption) * 10" [(page)]="pageBasic"
                                (pageChange)="onPageChange($event)">
                                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18"
                                        [class]="'font-weight-bolder'"></span>
                                </ng-template>
                                <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18"
                                        [class]="'font-weight-bolder'"></span></ng-template>
                            </ngb-pagination>
                        </div>
                
                    </ng-template>
                
                </ngx-datatable-footer>
        
            </ngx-datatable>
        </section>
    </div>
</div>
