import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DenemeComponent } from './deneme.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreCommonModule } from '@core/common.module';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, BlockUIModule } from 'ng-block-ui';



const routes: Routes = [
  {
    path: 'deneme',
    component: DenemeComponent,
    canActivate:[AuthGuard],
    data: { animation: 'auth' },
  },
];

@NgModule({
  declarations: [
    DenemeComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    CoreCommonModule,
    NgbPaginationModule,
    BlockUIModule.forRoot()
  
  ]
})
export class DenemeModule { }
