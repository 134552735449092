import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NewUserSidebarComponent } from './new-user-sidebar/new-user-sidebar.component';
import { CoreSidebarModule } from '@core/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'app/enum/role';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

const routes: Routes = [
  {
    path: 'user',
    component: UserComponent,
    canActivate:[AuthGuard],
    data: { roles:[Role.Supervisor,Role.Admin],animation: 'auth' },
  },
];

@NgModule({
  declarations: [
    UserComponent,
    NewUserSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    CoreSidebarModule,
    ContentHeaderModule,
    NgbModule,
    NgSelectModule,
    CoreCommonModule,

  ]
})
export class UserModule { }
