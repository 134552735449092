export const locale = {
  lang: 'tr',
  data: {
    ROLE: {
      Reading: 'Okuma',
      Writing: 'Yazma',
      Updateing: 'Güncelleme',
      Deleteing: 'Silme'
    },
    Definition: {
      User: {
        GetUsers: 'Tüm Kullanıcıları Getir',
        CreateUser: 'Kullanıcı Oluştur',
        UpdateUser: 'Kullanıcı Güncelle',
        GetRolestoUser: 'Kullanıcı Rolleri Al',
        AssignRoleToUser: 'Kullanıcıya Rol Ata',
        GetRolesToUser: 'Kullanıcı Rolleri Al'
      },
      LocationCategory: {
        GetAllLocationCategory: 'Tüm Konum Kategorilerini Al',
        GetByIdLocationCategory: 'Konum Kategorisini Id\'ye Göre Al',
        CreateLocationCategory: 'Konum Kategorisi Oluştur',
        RemoveLocationCategory: 'Konum Kategorisini Kaldır',
        UpdateLocationCategory: 'Kategori Güncelle'
      },
      LocationSubCategory: {
        GetAllLocationSubCategory: 'Tüm Konum Alt Kategorilerini Al',
        GetByIdLocationSubCategory: 'Konum Alt Kategorisini Id\'ye Göre Al',
        CreateLocationSubCategory: 'Konum Alt Kategorisi Oluştur',
        UpdateLocationSubCategory: 'Konum Alt Kategorisini Güncelle',
        RemoveLocationSubCategory: 'Konum Alt Kategorisini Kaldır'
      },
      Roles: {
        GetRoles: 'Tüm Rolleri Getir',
        GetRoleById: 'Rolü Id\'ye Göre Al',
        CreateRole: 'Rol Oluştur',
        UpdateRole: 'Rol Güncelle',
        DeleteRole: 'Rolü Sil'
      },
      Movement: {
        GetAllMovement: 'Tüm Hareketleri Getir',
        GetByIdMovement: 'Hareketi Id\'ye Göre Al',
        GetByCardMovement: 'Hareketi Karta Göre Al',
        CreateMovement: 'Hareket Oluştur'
      },
      Product: {
        GetAllProduct: 'Tüm Ürünleri Getir',
        GetByIdProduct: 'Ürünü Id\'ye Göre Al',
        GetByQrProduct: 'Ürünü QR Koda Göre Al',
        CreateProduct: 'Ürün Oluştur'
      },
      AuthorizationEndpoints: {
        GetRolesToEndpoint: 'Endpoint İçin Rolleri Getir',
        AssingRoleEndpoint: 'Role Endpoint Ata'
      },
      ApplicationServices: {
        GetAuthorizeDefinitionEndpoints: 'Yetki Tanım Endpoints Getir'
      }
    }
  }
}
