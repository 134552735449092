import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductTrasformDialogComponent } from './product-trasform-dialog/product-trasform-dialog.component';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { CoreCommonModule } from '@core/common.module';
import { NgSelectModule } from '@ng-select/ng-select';

LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    ProductTrasformDialogComponent
  ],
  imports: [
    CommonModule,
    NgxScannerQrcodeModule,
    CoreCommonModule,
    NgSelectModule
    
  ]
})
export class DialogsModule { }
