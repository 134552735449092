import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductComponent implements OnInit, AfterViewInit {

  constructor(private ngbModal:NgbModal) { }


  public contentHeader: object;

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Ürünler',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Ürün listesi',
            isLink: false,
          },
        ]
      }
    }
  }

}
