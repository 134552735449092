import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { CreateProduct } from 'app/dtos/product/create-product';
import { _isId } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClientService: HttpClientService) { }

  async getAllProducts(search,page,size) {
    const observable: Observable<any> = this.httpClientService.get({
      controller: ControllerType.Product,
      queryString:`search=${search}&Page=${page}&Size=${size}`
    });
    return await firstValueFrom(observable);
  }
  
  async getByQrProduct(mat_no: string, colorCode: string, tot_no: string, batch_no: string, production_date: string) {
    const observable: Observable<any> = this.httpClientService.post({
      controller: ControllerType.Product,
      action: "GetByQrProduct"
    }, {
      material_no: mat_no,
      colorCode: colorCode,
      tot_no: tot_no,
      batch_no,
      productionDate: production_date
    });
    return await firstValueFrom(observable);
  }
  async createProduct(createProduct: CreateProduct) {
    
    const observable: Observable<any> = this.httpClientService.post({
      controller: ControllerType.Product,
    }, {
      userId: parseInt(_isId.toString()),
      materialNo: createProduct.materialNo,
      productColorCode: createProduct.productColorCode,
      batchNo: createProduct.batchNo.toString(),
      totNo: createProduct.totNo.toString(),
      productionDate: createProduct.productionDate,
      viscositypackageFC325: createProduct.viscositypackageFC325.toString(),
      viscositypackageFC325Min: createProduct.viscositypackageFC325Min.toString(),
      viscositypackageFC325Max: createProduct.viscositypackageFC325Max.toString(),
      resistivitypackage: createProduct.resistivitypackage.toString(),
      resistivitypackageMin: createProduct.resistivitypackageMin.toString(),
      resistivitypackageMax: createProduct.resistivitypackageMax.toString(),
      solidsPackage: createProduct.solidsPackage.toString(),
      solidsPackageMin: createProduct.solidsPackageMin.toString(),
      solidsPackageMax: createProduct.solidsPackageMax.toString(),
      specificgravitypackage: createProduct.specificgravitypackage.toString(),
      specificgravitypackageMin: createProduct.specificgravitypackageMin.toString(),
      specificgravitypackageMax: createProduct.specificgravitypackageMax.toString(),
      startsagging1cmESTA: createProduct.startsagging1cmESTA.toString(),
      startsagging1cmESTAMin: createProduct.startsagging1cmESTAMin.toString(),
      startsagging1cmESTAMax: createProduct.startsagging1cmESTAMax.toString(),
      poppinglimitESTA: createProduct.poppinglimitESTA.toString(),
      poppinglimitESTAMin: createProduct.poppinglimitESTAMin.toString(),
      poppinglimitESTAMax: createProduct.poppinglimitESTAMax.toString(),
      scribeTest: createProduct.scribeTest,
      scribeTestMin: createProduct.scribeTestMin.toString(),
      scribeTestMax: createProduct.scribeTestMax.toString(),
      scribeTestRep: createProduct.scribeTestRep,
      scribeTestRepMin: createProduct.scribeTestRepMin.toString(),
      scribeTestRepMax: createProduct.scribeTestRepMax.toString(),
      craterTest: createProduct.craterTest,
      craterTestMin: createProduct.craterTestMin.toString(),
      craterTestMax: createProduct.craterTestMax.toString(),
      cleannessTest: createProduct.cleannessTest,
      cleannessTestMin: createProduct.cleannessTestMin.toString(),
      cleannessTestMax: createProduct.cleannessTestMax.toString(),
      glossEsta: createProduct.glossEsta.toString(),
      glossEstaMin: createProduct.glossEstaMin.toString(),
      glossEstaMax: createProduct.glossEstaMax.toString(),
      hidingPowerdry: createProduct.hidingPowerdry.toString(),
      hidingPowerdryMin: createProduct.hidingPowerdryMin.toString(),
      hidingPowerdryMax: createProduct.hidingPowerdryMax.toString(),
      stonechip2bar: createProduct.stonechip2bar.toString(),
      stonechip2barMin: createProduct.stonechip2barMin.toString(),
      stonechip2barMax: createProduct.stonechip2barMax.toString(),
      solidspackage60min10530min140: createProduct.solidspackage60min10530min140.toString(),
      solidspackage60min10530min140Min: createProduct.solidspackage60min10530min140Min.toString(),
      solidspackage60min10530min140Max: createProduct.solidspackage60min10530min140Max.toString(),
      stonechip: createProduct.stonechip.toString(),
      stonechipMin: createProduct.stonechipMin.toString(),
      stonechipMax: createProduct.stonechipMax.toString(),
      stonechipRephighbake: createProduct.stonechipRephighbake.toString(),
      stonechipRephighbakeMin: createProduct.stonechipRephighbakeMin.toString(),
      stonechipRephighbakeMax: createProduct.stonechipRephighbakeMax.toString(),
      adhesionTestOEM: createProduct.adhesionTestOEM.toString(),
      adhesionTestOEMMin: createProduct.adhesionTestOEMMin.toString(),
      adhesionTestOEMMax: createProduct.adhesionTestOEMMax.toString(),
      adhesiontestRephighbake: createProduct.adhesiontestRephighbake.toString(),
      adhesiontestRephighbakeMin: createProduct.adhesiontestRephighbakeMin.toString(),
      adhesiontestRephighbakeMax: createProduct.adhesiontestRephighbakeMax.toString(),
      dryhidingpowerblackgrey: createProduct.dryhidingpowerblackgrey.toString(),
      dryhidingpowerblackgreyMin: createProduct.dryhidingpowerblackgreyMin.toString(),
      dryhidingpowerblackgreyMax: createProduct.dryhidingpowerblackgreyMax.toString(),
      craterCount: createProduct.craterCount,
      craterCountMin: createProduct.craterCountMin.toString(),
      craterCountMax: createProduct.craterCountMax.toString(),
      dirtCount: createProduct.dirtCount,
      dirtCountMin: createProduct.dirtCountMin.toString(),
      dirtCountMax: createProduct.dirtCountMax.toString(),
      isWrong:createProduct.isWrong
    });
    
    return await firstValueFrom(observable);
  }

}


