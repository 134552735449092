import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationSubCategoryService {

  constructor(private httpClientService: HttpClientService) { }
  async createSubCategory(locationCategoryId: number, locationSubCategoryName: string) {
    const observable: Observable<any> = this.httpClientService.post({
      controller: ControllerType.LocationSubCategory
    }, {
      name: locationSubCategoryName,
      locationCategoryId: locationCategoryId
    });
    return await firstValueFrom(observable);
  }

  async removeLocationSubCategory(id: number) {
    const observable: Observable<any> = this.httpClientService.delete({
      controller: ControllerType.LocationSubCategory
    }, id);
    return await firstValueFrom(observable);
  }

  async updateLocationSubCategory(id: number, name: string, locationCategoryId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}` // Eğer yetkilendirme gerekiyorsa
    });
    const observable: Observable<any> = this.httpClientService.put({
      controller: ControllerType.LocationSubCategory,
      headars:headers
    }, {
      id: id,
      name: name,
      locationCategoryId: locationCategoryId
    });
    return await firstValueFrom(observable);
  }
  async getLocationSubCategory(id?: number, name?: string) {
    const observable: Observable<any> = this.httpClientService.get({
      controller: ControllerType.LocationSubCategory,
      action: "GetByIdLocationSubCategory",
      queryString: `id=${id}&name=${name}`
    });
    return await firstValueFrom(observable);
  }

}
