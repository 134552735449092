<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <app-product-list></app-product-list>
        </div>
      </div>
    </div>
  </div>
</div>


