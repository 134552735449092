import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, firstValueFrom} from 'rxjs';
import { Token } from 'app/dtos/auth/token';
import { Login } from 'app/dtos/auth/login';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserAuthServiceService {

  constructor(private httpClientService:HttpClientService,private toastService:ToastrService) { }

  async login(usernameoremail:string,password:string): Promise<void> {
   
      const result = await this.httpClientService.post<any>({
        controller: ControllerType.Auth,
        action: "Login"
      }, {
        usernameoremail:usernameoremail,
        password:password
      }).toPromise();
      if (result) {
        if(result.message){
         
          this.toastService.error(result.message);
        }
        else{
          localStorage.setItem("accessToken", result.accessToken!);
          localStorage.setItem("refreshToken", result.refreshToken!);
          this.toastService.success("Başarılı bir şekilde giriş yaptınız.");
        }
       
      }
  }

  async refrestTokenLogin(refreshToken:string,callBakcFunciton?:(state)=>void){
    const observable:Observable<any>=this.httpClientService.post({
      action:"RefreshTokenLogin",
      controller:ControllerType.Auth,
    },{
      refreshToken:refreshToken
    });
    try {
      const tokenResponse:any=await firstValueFrom(observable);
      if(tokenResponse){
        localStorage.setItem("accessToken", tokenResponse.accessToken);
        localStorage.setItem("refreshToken", tokenResponse.refreshToken);
      }
      callBakcFunciton(tokenResponse ? true:false);
    } catch (error) {
      callBakcFunciton(false);
    }
   }
  
}
