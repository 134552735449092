<!-- Right Sidebar -->
<div class="modal-dialog sidebar-lg">
    <div class="modal-content p-0">
        <form id="form-modal-todo" class="todo-modal needs-validation" (ngSubmit)="(todoForm.form.valid)"
            #todoForm="ngForm">
            <div class="modal-header align-items-center mb-1">
                <h5 class="modal-title">{{categoryType == false ? 'Yer Ekle':'Konum Ekle'}}</h5>
                <div class="todo-item-action d-flex align-items-center justify-content-between ml-auto">
                    <button *ngIf="isDataEmpty === false" (click)="closeSidebar()" type="button"
                        class="todo-item-favorite cursor-pointer mr-75 btn p-0">
                        x
                    </button>
                </div>
            </div>

            <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
                <div class="action-tags" *ngIf="!categoryType">
                    <label>Konumlar</label>
                    <div class="form-group">
                        <ng-select [items]="selectGroup" bindLabel="name" [(ngModel)]="selectSubCategory" name="selectSubCategory" [loading]="selectBasicLoading"> </ng-select>
                    </div>
                </div>
                <div class="form-group" *ngIf="!categoryType && selectSubCategory || categoryType">
                    <label for="locationSubCategoryTitle" class="form-label">{{categoryType == false ? 'Yer':'Konum'}}</label>
                    <input
                      type="text"
                      id="locationSubCategoryTitle"
                      name="locationsubCategoryTitle"
                      class="new-locationSubCategory-title form-control"
                      [class.error]="todoForm.submitted && TDNameRef.invalid"
                      placeholder="{{categoryType == false ? 'Alt yer':'Konum'}}"
                      [(ngModel)]="locationSubCategory"
                      #TDNameRef="ngModel"
                      required
                    />
                    <span *ngIf="todoForm.submitted && TDNameRef.invalid" class="invalid-form">
                      <small class="form-text text-danger" *ngIf="TDNameRef.errors.required">Boş olamaz.</small>
                    </span>
                  </div>
                <div class="form-group my-1">
                    <button type="submit" class="btn btn-primary add-todo-item mr-1" 
                        (click)="addTodo(todoForm)" rippleEffect>
                        Ekle
                    </button>

                    <!-- <button type="button" class="btn btn-primary update-btn update-todo-item mr-1"
                        *ngIf="isDataEmpty === false" (click)="updateTodo()" rippleEffect>
                        Update
                    </button> -->

                    <button class="btn btn-outline-secondary add-todo-item" type="button" (click)="closeSidebar()"
                        data-dismiss="modal" rippleEffect>
                        İptal
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<!--/ Right Sidebar -->