import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { _isId } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class MovementService {

  constructor(private httpClientService:HttpClientService) { }

  async createMovemnt(productId,locationSubCategoryId,userId){
    const observable:Observable<any> = this.httpClientService.post({
      controller:ControllerType.Movement
    },{
      productId:productId,
      locationSubCategoryId:locationSubCategoryId,
      userId:_isId
    });
    return await firstValueFrom(observable);
  }
  async getBasketProduct(locationSubCategoryName,userId){
    const observable:Observable<any> = this.httpClientService.get({
      controller:ControllerType.Movement,
      action:"GetBasketProduct",
      queryString:`id=${userId}&subCategoryName=${locationSubCategoryName}`
    });
    return await firstValueFrom(observable);
  }
    
  private eventSubject = new Subject<boolean>();

  event$ = this.eventSubject.asObservable();

  sendEvent(result: boolean) {
    this.eventSubject.next(result);
  }

}
