export class ProductValue{
     list = [
        "viscositypackageFC325",
        "resistivitypackage",
        "solidsPackage",
        "specificgravitypackage",
        "startsagging1cmESTA",
        "poppinglimitESTA",
        "scribeTest",
        "scribeTestRep",
        "craterTest",
        "cleannessTest",
        "glossEsta",
        "hidingPowerdry",
        "stonechip2bar",
        "solidspackage60min10530min140",
        "stonechip",
        "stonechipRephighbake",
        "adhesionTestOEM",
        "adhesiontestRephighbake",
        "dryhidingpowerblackgrey",
        "craterCount",
        "dirtCount"
    ]
}