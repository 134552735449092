import { CoreMenu } from '@core/types'
import { _isRole } from 'app/services/auth.service'

export const menu: CoreMenu[] = [
  {
    id: 'product',
    title: 'Ürünler',
    translate: 'MENU.PRODUCT',
    type: 'item',
    icon: 'file',
    url: '/admin/product',
    role:['admin','supervisor'],
  },
  {
    id: 'location-category',
    title: 'Depolar',
    translate: 'MENU.LOCATIONCATEGORY',
    type: 'item',
    icon: 'map',
    url: '/admin/location-category',
    role:['admin','supervisor'],
  },
  {
    id: 'user',
    title: 'Kullanıcılar',
    translate: 'MENU.USER',
    type: 'item',
    icon: 'user',
    url: '/admin/user',
    role:['supervisor','admin'],
  },
  {
    id: 'role',
    title: 'Roller',
    translate: 'MENU.ROLE',
    type: 'item',
    icon: 'unlock',
    url: '/admin/role',
    role:['admin'],
  },
  {
    id:'qr',
    title:'QR İşlemleri',
    translate:'MENU.QR',
    type:'item',
    icon:'camera',
    url:'/admin/qr',
    role:['admin','supervisor','user'],
  },
  {
    id:'win',
    title:'Boya İşlemleri',
    translate:'MENU.WIN',
    type:'item',
    icon:'camera',
    url:'/admin/win',
    role:['admin','supervisor','user'],
  },
  // {
  //   id:'deneme',
  //   title:'deneme',
  //   translate:'MENU.DENEME',
  //   type:'item',
  //   icon:'camera',
  //   url:'/admin/deneme',
  //   role:['admin','supervisor','user'],
  // }
  
]
