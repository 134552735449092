import { Role } from './../../../enum/role';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, id } from '@swimlane/ngx-datatable';
import { AuthService } from 'app/services/auth.service';
import { RoleService } from 'app/services/models/role.service';
import { UserService } from 'app/services/models/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {
  public userId;
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private userService: UserService,
    private roleService: RoleService,
    private modalService: NgbModal,
    public authService:AuthService,
    private toastService: ToastrService) { 
      //authService.identityCheck();
      this.userId = authService.isId
    }
    public contentHeader: object;
    public ColumnMode = ColumnMode;

  rows: any;

  public selectedRole: any = null;
  public selectRole = [];

  public previousRoleFilter = '';
  public pageBasic = 1;
  public selectedSize = 5;
  public basicSelectedOption: number = 5;
  public searchValue = '';
  totalCount: Number = 0;

  filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    this.searchValue = val;
    setTimeout(async () => {

      await this.getAllUsers(this.searchValue,this.pageBasic - 1, this.selectedSize);
    }, 2000);
  }

  async getAllRoles() {
    const datas = await this.roleService.getAllRoles();
    this.selectRole = datas
    this.selectRole=datas;
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  async ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Kullanıcı',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Kullanıcı listesi',
            isLink: false,
          },
        ]
      }
    }
    await this.getAllUsers(this.searchValue,this.pageBasic - 1, this.selectedSize);
    await this.getAllRoles();
    this.userService.event$.subscribe(async result => {
      await this.getAllUsers(this.searchValue,this.pageBasic - 1, this.selectedSize);
    })

  }

  async getAllUsers(seacrh,page,size) {
    try {
      const data = await this.userService.getAllUsers(seacrh,page,size);
      this.rows = data.users;
      
      this.totalCount = data.totalCount;
    } catch (error) {
    }
  }
  async onPageChange(pageNumber: number) {
    await this.getAllUsers(this.searchValue,this.pageBasic - 1, this.selectedSize);
  }
  async onOptionChange() {

    this.selectedSize = Number(this.basicSelectedOption)
    await this.getAllUsers(this.searchValue,this.pageBasic-1,this.selectedSize);
  }

  userName: string;
  userNameAndLastName:string;
  userPassword: string;

  async updateLocationCategory(user, modalForm) {
   
    const modalRef = this.modalService.open(modalForm, {
      windowClass: 'modal',
    });
    this.userName = user.userName;
    this.selectedRole = user.role ? user.role[0] : null;
    this.userNameAndLastName = user.userNameOrLastName;
    try {
      const result = await modalRef.result;
      if (result === 'Success') {
        const data = await this.userService.updateUser(user.id, this.userName,this.userNameAndLastName, this.userPassword);
        if (data.status == true) {
          this.toastService.success(data.message);
          this.userService.sendEvent(true);

        } else
          this.toastService.error(data.message);
        
      }
      if (result === 'Roles') {
      
        const data = await this.userService.assignRoleToUser(user.id,[this.selectedRole.name]);
        if (data.status == true) {
          this.toastService.success(data.message);
          this.userService.sendEvent(true);

        } else
          this.toastService.error(data.message);
      }
    } catch (error) {
    }
  }

}
