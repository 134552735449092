<!-- Basic Alerts start -->
<section id="ngx-datatable" *blockUI="'section-block'">
    <div class="row">
        <div class="col-12 mb-50">
           
        </div>
        <div class="col-md-6 col-12">
      
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Gösterilecek satır
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                        (ngModelChange)="onOptionChange()">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="50">50</option>
                    </select>
                    sayısı
                </label>
            </div>
        </div>
        
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a  class="btn btn-outline-secondary float-right mr-1" (click)="exportAsExcelFile()">
                    <i data-feather="download" class="mr-25"></i>
                  </a>
    
            </div>
           
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center"><i data-feather="search" class="mr-25"></i>
                    <input type="search" placeholder="Bul"
                        class="form-control ml-25" 
                        (input)="filterUpdate($event.target.value)"
                        (change)="filterUpdate($event.target.value)"
                        [value]="searchValue" />
                </label>
            </div>
            
        </div>
    </div>

    <ngx-datatable class="bootstrap core-bootstrap" [messages]="{ emptyMessage: 'Gösterilecek veri bulunamadı' }" [columnMode]="ColumnMode.force" [headerHeight]="40" [rowHeight]="58"
        [footerHeight]="50" [count]="totalCount" [limit]="basicSelectedOption" rowHeight="auto" [rows]="rows"
        [scrollbarH]="true">

        <ngx-datatable-column name="Ürün Kodu" prop="materialNo"></ngx-datatable-column>
        <ngx-datatable-column name="Renk Kodu" prop="productColorCode"></ngx-datatable-column>
        <ngx-datatable-column name="Tot No" prop="totNo"></ngx-datatable-column>
        <ngx-datatable-column name="Bulunduğu Konum">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.locationCategory ? row.locationCategory + '-' + row.locationSubCategory : 'Bulunamadı.' }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Hatalı">
            <ng-template let-row="row" ngx-datatable-cell-template>
                
                <i [data-feather]="row.isWrong?'x':'check'" style="color: red;" class="mr-25"></i>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
                <div class="datatable-footer-inner">
                    <div class="page-count">
                        Toplam Veri {{ totalCount }}
                    </div>


                    <ngb-pagination [collectionSize]="(totalCount / basicSelectedOption) * 10" [(page)]="pageBasic"
                        (pageChange)="onPageChange($event)">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18"
                                [class]="'font-weight-bolder'"></span>
                        </ng-template>
                        <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18"
                                [class]="'font-weight-bolder'"></span></ng-template>
                    </ngb-pagination>
                </div>

            </ng-template>

        </ngx-datatable-footer>
    </ngx-datatable>

</section>