import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductModule } from './product/product.module';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { LocationCategoryModule } from './location-category/location-category.module';
import { UserModule } from './user/user.module';
import { RoleModule } from './role/role.module';
import { QrModule } from './qr/qr.module';
import { DenemeModule } from './deneme/deneme.module';
import { WinModule } from './win/win.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProductModule,
    LocationCategoryModule,
    UserModule,
    RoleModule,
    QrModule,
    CoreCommonModule,
    ContentHeaderModule,
    DenemeModule,
    WinModule
  ]
})
export class AdminModule { }
