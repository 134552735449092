import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WinComponent } from './win.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { Role } from 'app/enum/role';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: 'win',
    component: WinComponent,
    canActivate:[AuthGuard],
    data: { roles:[Role.Supervisor,Role.Admin],animation: 'auth' },
  },
];

@NgModule({
  declarations: [
    WinComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    NgxDatatableModule,
    NgSelectModule,
    NgbModule
  ]
})
export class WinModule { }
