import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-location-category',
  templateUrl: './location-category.component.html',
  styleUrls: ['./location-category.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class LocationCategoryComponent implements OnInit {

  isSubCategory=true;

  
  constructor(private _coreSidebarService:CoreSidebarService) {
   
   }
  public contentHeader: object;



  toggleSidebarSubCategory(nameRef): void {
   this.isSubCategory = true;
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
    
  }

  toggleSidebarCategory(nameRef): void {
    this.isSubCategory = false;
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Konum',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Konum listesi',
            isLink: false,
          },
        ]
      }
    }
  }

}
