export const locale = {
  lang: 'en',
  data: {
    MENU: {
      PRODUCT: 'Ürünler',
      LOCATIONCATEGORY: 'Konumlar',
      USER:'Kullanıcılar',
      ROLE:'Roller',
      QR:'QR İşlemleri',
      DENEME:"Deneme",
      WIN:"Win"
    }
  }
}
