import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrComponent } from './qr.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { DialogsModule } from 'app/dialogs/dialogs.module';
import { NgSelectModule } from '@ng-select/ng-select';

LOAD_WASM().subscribe();

const routes: Routes = [
  {
    path: 'qr',
    component: QrComponent,
    canActivate:[AuthGuard],
    data: { animation: 'auth' },
  },
];

@NgModule({
  declarations: [
    QrComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxScannerQrcodeModule,
    CoreCommonModule,
    CardSnippetModule,
    DialogsModule,
    NgSelectModule,
    
  ]
})
export class QrModule { }
