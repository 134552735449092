<div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">Ürün Taşıma Hizmeti</h5>

    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="row" id="table-bordered">
        <div class="col-12">
            <div class="card">
                <div class="col-sm-6 col-12">
                    <label for="selectedDevice">Kamera Seçiniz</label>
                    <div class="form-group">
                        <ng-select [items]="cameras" bindLabel="label" name="selectedDevice"
                            [(ngModel)]="selectedDevice">
                            <ng-template ng-option-tmp let-item="item">
                                <i data-feather="camera"></i>
                                {{ item.label }}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <button style="height: 50px; width: 100%;" type="button" (click)="scannerOpen()"
                            class="btn btn-outline-primary" rippleEffect>
                            <span [data-feather]="'camera'" [class]="'mr-25'"></span>{{action.isStart ? 'Durdur' :
                            'Taşınacak Konumu
                            Okut'}}
                        </button>
                    </div>
                </div>
                <ngx-scanner-qrcode #action="scanner" style="width: 100%;" [hidden]="!action.isStart" isBeep="true"
                    (event)="handleScanSuccess($event)"></ngx-scanner-qrcode>

                <p *ngIf="action.isLoading">⌛ Yükleniyor...</p>
                <h1 style="text-align: center;">Ürünü taşıncağı yerin etiketini okutunuz.</h1>
                
                <h1 style="text-align: center;" *ngIf="readQR">{{locationSubCateogy?.name}} konumuna taşınacaktır.</h1>
                <button  [disabled]="locationSubCateogy==null?true:false" style="text-align: center;" type="button" *ngIf="readQR" (click)="createMovement()"
                    class="btn btn-success" rippleEffect>
                    <span [data-feather]="'plus'" [class]="'mr-25'"></span>Ürünü Taşı</button>
            </div>


        </div>
    </div>
</div>