<li ngbDropdown class="nav-item dropdown-cart mr-25">
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown"><span [data-feather]="'shopping-cart'"
      [class]="'ficon'"></span><span class="badge badge-pill badge-danger badge-up cart-item-count">{{ cartListLength
      }}</span></a>
  <ul ngbDropdownMenu aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Üzerime Zimmetli Ürünler</h4>
        <div class="badge badge-pill badge-danger">{{ cartListLength ?? 0 }} Ürün</div>
      </div>
    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <ng-container *ngIf="products.length">
        <ng-container *ngFor="let product of products;let i = index">
          <ul class="list-group">
            <li class="list-group-item">
              {{i+1}}-)
              {{ product.materialNo }}
              <!-- entryDate altına küçük bir metin ekleyelim -->
              <div style="font-size: smaller;">Giriş Tarihi: {{ product.entryDate | date:'dd/MM/yyyy HH:mm'}}</div>
              <div style="font-size: smaller;">Üretim Tarihi: {{ product.productionDate }}</div>
              <div style="font-size: smaller;">Batch No: {{ product.batchNo }}</div>
              <div style="font-size: smaller;">Tot No: {{ product.totNo }}</div>
            </li>
          </ul>
        </ng-container>
        
      </ng-container>
    </li>


  </ul>
</li>