import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationCategoryService {

  constructor(private httpClientService:HttpClientService) { }

  async getAllLocationCategory(page,size,name?:any){
    const observable:Observable<any> = this.httpClientService.get({
      controller:ControllerType.LocationCategory,
      queryString:`Page=${page}&Size=${size}&name=${name}`
    });
    return await firstValueFrom(observable);
  }

  async createLocationCategory(locationName:string){
    const observable:Observable<any> = this.httpClientService.post({
      controller:ControllerType.LocationCategory
    },{
      name:locationName
    });
    return await firstValueFrom(observable);
  }

  async removeLocationCategory(id:number){
    const observable:Observable<any> = this.httpClientService.delete({
      controller:ControllerType.LocationCategory
    },id);
    return await firstValueFrom(observable);
  }
  async updateLocationCategory(id:number,name:string){
    const observable:Observable<any> = this.httpClientService.put({
      controller:ControllerType.LocationCategory,
      
    },{
      id:id,
      name:name
    });
    return await firstValueFrom(observable);
  }

  private eventSubject = new Subject<boolean>();

  event$ = this.eventSubject.asObservable();

  sendEvent(result: boolean) {
    this.eventSubject.next(result);
  }
  
}
