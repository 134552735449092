import { NgModule } from '@angular/core';
import { HashLocationStrategy,LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { JwtModule } from "@auth0/angular-jwt";
import { AuthGuard } from './guards/auth.guard';
import { AuthLoginV2Component } from './main/pages/authentication/auth-login-v2/auth-login-v2.component';

import { ColumnChangesService, DimensionsHelper, ScrollbarHelper } from '@swimlane/ngx-datatable';

import { AuthRegisterV1Component } from './main/pages/authentication/auth-register-v1/auth-register-v1.component';
import { PagesModule } from './main/pages/pages.module';
import { AdminModule } from './main/admin/admin.module';
import { HttpErrorHandlerInterceptorService } from './services/HttpErrorHandlerInterceptorService';
import { HomeComponent } from './main/pages/home/home.component';


const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(p => p.PagesModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./main/admin/admin.module').then(p => p.AdminModule),
  },
  {
    path:'',
    redirectTo:'/admin/qr',
    pathMatch:'full'
  },
  /*
  {
    path:'',
    component:HomeComponent,
    loadChildren: ()=>import('./main/pages/home/home.module').then(p=>p.HomeModule),
  },*/
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem("accessToken"),
        allowedDomains: ["boyatakip.ford.kaynakyazilim.com.tr:444"],
      }
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    AdminModule,
    PagesModule,
    // App modules
    LayoutModule,

  ],
  providers: [
    ScrollbarHelper,
    DimensionsHelper,
    ColumnChangesService,
    {
      provide: "baseurl", useValue: "https://boyatakip.ford.kaynakyazilim.com.tr:444/api", multi: true
    },
    {
      provide:HTTP_INTERCEPTORS, useClass:HttpErrorHandlerInterceptorService,multi:true
    },{
      provide:LocationStrategy,
      useClass:HashLocationStrategy
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
