import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { LocationCategoryService } from 'app/services/models/location-category.service';
import { LocationSubCategoryService } from 'app/services/models/location-sub-category.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-right-location-category',
  templateUrl: './right-location-category.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RightLocationCategoryComponent implements OnInit {
  @Input() categoryType: boolean;

  constructor(
    private _coreSidebarService:CoreSidebarService,
    private locationCategoryService:LocationCategoryService,
    private locationSubCategoryService:LocationSubCategoryService,
    private toastService:ToastrService,) { }
 

  public isDataEmpty;
  public selectAssignee;
  public locationSubCategory: any;
  public selectSubCategory:any;
  public selectBasic: any[] = [];
  public selectBasicLoading = false;

  public selectGroup = [];

  async ngOnInit() {

    this.isDataEmpty = false;
   this.getAllCategories();
  }

  async getAllCategories(){
    const data = await this.locationCategoryService.getAllLocationCategory(0,100,'');

     this.selectGroup = data.getAllLocationCategories;
  }

  closeSidebar() {
    this._coreSidebarService.getSidebarRegistry('app-right-location-category').toggleOpen();
  }

  
  async addTodo(todoForm:NgForm) {
    if (todoForm.valid) {
      if(this.categoryType){
       const data = await this.locationCategoryService.createLocationCategory(todoForm.value.locationsubCategoryTitle);
        if(data.status==true){
          this.toastService.success(data.message);
          this.locationCategoryService.sendEvent(true);
          this.getAllCategories();
        }else
          this.toastService.error(data.message);
        
      }else{
        const data = await this.locationSubCategoryService.createSubCategory(todoForm.value.selectSubCategory.id,todoForm.value.locationsubCategoryTitle);
        if(data.status==true){
          this.toastService.success(data.message);
          this.locationCategoryService.sendEvent(true);
          this.getAllCategories();
        }else
          this.toastService.error(data.message);
      }
      this.locationSubCategory = null;
      this.selectSubCategory = null
    }
  }

  updateTodo() {
    //! Fix: Temp fix till ng2-flatpicker support ng-modal (Getting NG0100: Expression has changed after it was checked error if we use ng-model with ng2-flatpicker)
    //this.todo.dueDate = this.dueDateRef.flatpickrElement.nativeElement.children[0].value;

    //this._todoService.updateCurrentTodo(this.todo);
    this.closeSidebar();
  }

}
