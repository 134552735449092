import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/services/models/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-user-sidebar',
  templateUrl: './new-user-sidebar.component.html',
  encapsulation:ViewEncapsulation.None
})
export class NewUserSidebarComponent implements OnInit {

  constructor(
    private _coreSidebarService:CoreSidebarService,
    private formBuilder: FormBuilder,
    private userService:UserService,
    private toastService:ToastrService) { }

  public username;
  public userNameAndLastName;
  public password;
  public submitted = false;
  public passwordTextType: boolean;
  public loginForm: FormGroup;

  ngOnInit(): void {
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  async submit(form:NgForm) {
    if (form.valid) {
      this.toggleSidebar('new-user-sidebar');
      const data = await this.userService.registerUser(form.value.username,form.value.userNameAndLastName,form.value.userpassword);

      if(data.succedded==true){
        this.toastService.success(data.message);
        this.userService.sendEvent(true);
      }
        
      else
        this.toastService.error(data.message);
    }
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  get f() {
    return this.loginForm.controls;
  }

  
}
