import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LocationCategoryService } from 'app/services/models/location-category.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-deneme',
  templateUrl: './deneme.component.html',
  styleUrls: ['./deneme.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DenemeComponent implements OnInit {
  rows: any;
  @BlockUI('section-block') blockUI: NgBlockUI;

  totalCount: Number = 0;
  public ColumnMode = ColumnMode;
  public pageBasic = 1;
  public selectedSize = 5;
  public basicSelectedOption: number = 5;
  public searchValue = '';


  constructor(private locationCategoryService: LocationCategoryService) {

  }

  async filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    this.searchValue = val;
    await this.getAllLocation(this.pageBasic - 1, this.selectedSize, this.searchValue);

  }

  async ngOnInit() {
    await this.getAllLocation(this.pageBasic - 1, this.selectedSize, this.searchValue);
  }

  async getAllLocation(page, size, name) {
    this.blockUI.start()
    const data = await this.locationCategoryService.getAllLocationCategory(page, size, name);
    this.rows = data.getAllLocationCategories;
    this.totalCount = data.count;
    this.blockUI.stop();
  }

  onPageChange(pageNumber: number) {
    this.getAllLocation(this.pageBasic - 1, this.selectedSize, this.searchValue);
  }
  async onOptionChange() {

    this.selectedSize = Number(this.basicSelectedOption)
    await this.getAllLocation(this.pageBasic - 1, this.selectedSize, this.searchValue)
  }
}
