<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    Telif Hakkı &copy; {{ year }}
    <a class="ml-25" href="https://kurgu-e.com/" target="_blank">Kurgu-e</a>
    <span class="d-none d-sm-inline-block">, Her hakkı saklıdır.</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    Kurgu Elektronik <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span>
</p>

<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>


