<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="col-sm-6 col-12">
                        <label for="selectedDevice">Kamera Seçiniz</label>
                        <div class="form-group">
                            <ng-select [items]="cameras" bindLabel="label" name="selectedDevice"
                                [(ngModel)]="selectedDevice">
                                <ng-template ng-option-tmp let-item="item">
                                    <i data-feather="camera"></i>
                                    {{ item.label }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <button style="height: 50px; width: 100%;" type="button" (click)="scannerOpen()"
                                class="btn btn-outline-primary" rippleEffect>
                                <span [data-feather]="'camera'" [class]="'mr-25'"></span>{{action.isStart ? 'Durdur' :
                                'Ürün
                                Okut'}}
                            </button>
                        </div>
                    </div>
                    <ngx-scanner-qrcode #action="scanner" [hidden]="!action.isStart" style="width: 100%;" isBeep="true"
                        (event)="handleScanSuccess($event, modalXL)"></ngx-scanner-qrcode>
                    <p *ngIf="action.isLoading">⌛ Yükleniyor...</p>
                </div>
            </div>




        </div>
    </div>
</div>


<!-- ürün rapor modal -->
<ng-template #modalXL let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">Ürün Raporu</h5>

        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row" id="table-bordered">
            <div class="col-12">
                <div class="card">
                    <button type="button" style="height: 75px;" class="btn btn-primary"
                         *ngIf="qrdata!.length == 26 && getByQrData == null"
                        (click)="modal.close('Accept click')" rippleEffect>
                        Ürünü Onayla
                    </button>

                    <button type="button" style="height: 75px;" class="btn btn-success" [disabled]="isProductionProduct"
                        *ngIf="qrdata!.length == 26 && getByQrData != null" (click)="modal.close('ProductTransport')"
                        rippleEffect>
                        Ürünü Taşı
                    </button>
                    <p></p>
                    <button type="button" style="height: 75px;" class="btn btn-info" [disabled]="isProductionProduct"
                        *ngIf="qrdata!.length == 26 && getByQrData != null" (click)="modal.close('Owner')" rippleEffect>
                        Kendine zimmetle
                    </button>

                    <div class="card-body" *ngIf="getByQrData == null">
                        <p class="card-text" [style.color]="filteredCount > 0 ? 'red' : 'green'">
                            {{ filteredCount > 0 ? (filteredCount + ' veriden geçemedi.') : 'Tüm testlerden başarılı bir
                            şekilde geçti.' }}
                        </p>
                    </div>
                    <div class="table-responsive" *ngIf="getByQrData == null">
                        <table class="table table-bordered">

                            <tr>
                                <td>Mat No</td>
                                <td>{{qrdata[0].alt_limit}}</td>
                            </tr>
                            <tr>
                                <td>Renk kodu</td>
                                <td>{{qrdata[1].alt_limit}}</td>
                            </tr>
                            <tr>
                                <td>Batch No</td>
                                <td>{{qrdata[2].deger}}</td>
                            </tr>

                            <tr>
                                <td>Tot No</td>
                                <td>{{qrdata[3].deger}}</td>

                            </tr>
                            <tr>
                                <td>Üretim Tarihi</td>
                                <td>{{qrdata[4].deger}}</td>
                            </tr>
                        </table>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Alt limit</th>
                                    <th>Ust Limit</th>
                                    <th>Değer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of qrdata; let i = index"
                                    [ngClass]="{'bg-danger': !item.uygunmu, 'bg-success': item.uygunmu}">
                                    <td #tdWidth style="width: auto; color:black" *ngIf="i>4">{{productValue[i - 5]}}
                                    </td>
                                    <td #tdWidth style="width: auto; color:black" *ngIf="i>4">{{item.alt_limit}}</td>
                                    <td #tdWidth style="width: auto; color:black" *ngIf="i>4">{{item.ust_limit}}</td>
                                    <td #tdWidth style="width: auto; color:black" *ngIf="i>4">{{item.deger}}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="table-responsive" *ngIf="getByQrData != null">
                        <h1>Ürün Bilgileri ve Hareketleri</h1>
                        <table class="table table-bordered">

                            <tr>
                                <td>Mat No</td>
                                <td>{{getByQrData.materialNo}}</td>
                            </tr>
                            <tr>
                                <td>Renk kodu</td>
                                <td>{{getByQrData.productColorCode}}</td>
                            </tr>
                            <tr>
                                <td>Batch No</td>
                                <td>{{getByQrData.batchNo}}</td>
                            </tr>

                            <tr>
                                <td>Tot No</td>
                                <td>{{getByQrData.totNo}}</td>

                            </tr>
                            <tr>
                                <td>Üretim Tarihi</td>
                                <td>{{getByQrData.productionDate}}</td>
                            </tr>
                        </table>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Bulunduğu Konum</th>
                                    <th>Alt Konum</th>
                                    <th>Giriş Tarihi</th>
                                    <th>Çıkış Tarihi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let movement of getByQrData.movements; let i = index">
                                    <td #tdWidth style="width: auto;">{{movement.locationName}}</td>
                                    <td #tdWidth style="width: auto;">{{movement.locationSubName}}</td>
                                    <td #tdWidth style="width: auto;">{{movement.entryDate | date:'dd/MM/yyyy HH:mm'}}
                                    </td>
                                    <td #tdWidth style="width: auto;">
                                    <p *ngIf="movement.locationId!=3">{{movement.releaseDate == null ? "Çıkış Yapmadı"
                                        :movement.releaseDate | date:'dd/MM/yyyy HH:mm' }}</p>    
                                        <p *ngIf="movement.locationId == 3">{{movement.releaseDate??'Stokda Yok' }}</p>    
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">


    </div>
</ng-template>