import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WinService {

  constructor(private httpClientService:HttpClientService) { }
  async getAllWin(page,size,search){
    const observable:Observable<any>=this.httpClientService.get({
      controller:ControllerType.Win,
      queryString:`Page=${page}&Size=${size}&serach=${search}`
    });
    return await firstValueFrom(observable);
  }
}
