import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ProductService } from 'app/services/models/product.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ProductListComponent implements OnInit {

  constructor(private productService: ProductService, private activadetRouter: ActivatedRoute) { }
  @BlockUI('section-block') blockUI: NgBlockUI;
  rows = [];
  totalCount: Number = 0;
  public exportCSVData;
  public pageBasic = 1;
  public selectedSize = 5;
  public basicSelectedOption: number = 5;
  public searchValue = '';
  public ColumnMode = ColumnMode;

  async ngOnInit() {
   
   
    this.activadetRouter.params.subscribe(async params => {
      const searchParam = params['search'];
    
      if (searchParam) {
        this.searchValue = searchParam.toLowerCase();
        await this.filterUpdate(this.searchValue)
      }else
      await this.getAllProduct(this.searchValue, this.pageBasic - 1, this.selectedSize);
     
    });

  }


  async filterUpdate(event) {

    const val = event.toLowerCase();

    this.searchValue = val;
    setTimeout(async () => {

      await this.getAllProduct(this.searchValue, this.pageBasic - 1, this.selectedSize);
    }, 2000);

    // 

  }

  async getAllProduct(search, page, size) {
    this.blockUI.start()
    const datas = await this.productService.getAllProducts(search, page, size);
    // this.blockUI.stop();
    this.rows = datas.products;
    this.totalCount = datas.count
  
    this.exportCSVData = this.exportExcel();
    this.blockUI.stop()
  }

  exportExcel() {
    const exportedData = [];
    this.rows.forEach(item => {
      const rowData = {
        'Ürün Kodu': item.materialNo,
        'Renk Kodu': item.productColorCode,
        'Tot No': item.totNo,
        'Batch No': item.batchNo,
        'Üretim Tarihi': item.productionDate,
        'Konum': item.locationCategory,
        'Alt Konum':item.locationSubCategory
      };
      exportedData.push(rowData);
    });
    return exportedData;
  }

  exportAsExcelFile() {
    const data = this.exportExcel();
    if (data.length > 0) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'urunlistesi.xlsx');
    } else {

    }
  }

  private saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, baseFileName);
  }
  async onPageChange(pageNumber: number) {
    await this.getAllProduct(this.searchValue, this.pageBasic - 1, this.selectedSize);
  }
  async onOptionChange() {

    this.selectedSize = Number(this.basicSelectedOption)
    await this.getAllProduct(this.searchValue, this.pageBasic - 1, this.selectedSize);
  }
}
