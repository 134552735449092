import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent, id } from '@swimlane/ngx-datatable';
import { LocationCategoryService } from 'app/services/models/location-category.service';
import { LocationSubCategoryService } from 'app/services/models/location-sub-category.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-list-location-category',
  templateUrl: './list-location-category.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ListLocationCategoryComponent implements OnInit {
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private locationCategoryService: LocationCategoryService,
    private loacationSubCategoryService: LocationSubCategoryService,
    private loadingService: CoreLoadingScreenService,
    private toastService: ToastrService,
    private modalService: NgbModal,

  
  ) { }

  rows = [];
  public pageBasic = 1;
  public basicSelectedOption: number = 5;
  public selectedSize = 5;
  public ColumnMode = ColumnMode;
  locationCategory: string;
  public searchValue = '';
  totalCount: Number = 0;
  public exportCSVData = [];

  async ngOnInit() {
    this.loadingService.show();
    this.locationCategoryService.event$.subscribe(async result => {
      await this.getAllLocationCategory(this.pageBasic - 1, this.basicSelectedOption, this.searchValue);
    })
    await this.getAllLocationCategory(this.pageBasic - 1, this.basicSelectedOption, this.searchValue);
    this.loadingService.hide();
  }

  filterUpdate(event) {
    const val = event.target.value.toLowerCase();
    this.searchValue = val;
    this.getAllLocationCategory(this.pageBasic - 1, this.selectedSize, this.searchValue)
  }

  onPageChange(pageNumber: number) {
    this.getAllLocationCategory(this.pageBasic - 1, this.selectedSize, this.searchValue);
  }

  async onOptionChange() {

    this.selectedSize = Number(this.basicSelectedOption)
    await this.getAllLocationCategory(this.pageBasic - 1, this.selectedSize, this.searchValue)
  }

  async removeLocationSubCategory(id, modalBasic) {
    const modalRef = this.modalService.open(modalBasic, {
      windowClass: 'modal',
    });
    try {
      const result = await modalRef.result;

      if (result === 'Success') {
        const data = await this.loacationSubCategoryService.removeLocationSubCategory(id);
        if (data.status == true) {
          this.toastService.success(data.message);
          this.locationCategoryService.sendEvent(true);
        } else
          this.toastService.error(data.message);
      }
    } catch (error) {

    }
  }

  async removeLocationCategory(id, modalBasic) {
    const modalRef = this.modalService.open(modalBasic, {
      windowClass: 'modal',
    });
    try {
      const result = await modalRef.result;

      if (result === 'Success') {
        const data = await this.locationCategoryService.removeLocationCategory(id);
        if (data.status == true) {
          this.toastService.success(data.message);
          this.locationCategoryService.sendEvent(true);

        } else
          this.toastService.error(data.message);
      }
    } catch (error) {
   
    }
  }

  async updateLocationCategory(locationCategory, modalForm) {
    const modalRef = this.modalService.open(modalForm, {
      windowClass: 'modal',
    });
    this.locationCategory = locationCategory.name;
    try {
      const result = await modalRef.result;
      if (result === 'Success') {
        const data = await this.locationCategoryService.updateLocationCategory(locationCategory.id, this.locationCategory);
        if (data.status == true) {
          this.toastService.success(data.message);
          this.locationCategoryService.sendEvent(true);

        } else
          this.toastService.error(data.message);

      }
    } catch (error) {
   
    }
  }

  async updateLocationSubCategory(locationCategoryId, locationSubCategory, modalForm) {
    const modalRef = this.modalService.open(modalForm, {
      windowClass: 'modal',
    });
    this.locationCategory = locationSubCategory.name;
    try {
      const result = await modalRef.result;
      if (result === 'Success') {
        const data = await this.loacationSubCategoryService.updateLocationSubCategory(locationSubCategory.id, this.locationCategory, locationCategoryId);
        if (data.status == true) {
          this.toastService.success(data.message);
          this.locationCategoryService.sendEvent(true);

        } else
          this.toastService.error(data.message);

      }
    } catch (error) {

    }
  }

  async getAllLocationCategory(page, size, name) {
    
    const data = await this.locationCategoryService.getAllLocationCategory(page, size, name);
   
    this.rows = data.getAllLocationCategories;

    this.totalCount = data.count;
    this.exportCSVData = this.exportExcel();
  }
  exportExcel() {
    const exportedData = this.rows?.reduce((accumulator, item) => {
      if(item.locationSubCategoryDtos.length == 0){
        const subRow = {'Ana Konum':item.name , 'Alt Konum':'Yok','Toplam Ürün Sayısı':''}
        accumulator.push(subRow);
      }
      item.locationSubCategoryDtos?.forEach(subItem => {
        const subRow = { 'Ana Konum': item.name, 'Alt Konum': subItem.name, 'Toplam Ürün Sayısı': subItem.movementCount };
        accumulator.push(subRow);
      });
      return accumulator;
    }, []);
  
    return exportedData;
  }
  
  exportAsExcelFile() {
    const data = this.exportExcel();
    if (data.length > 0) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'location_data.xlsx');
    } else {
      // Handle case when there is no data to export

    }
  }
  
  private saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, baseFileName);
  }
  

  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.toggleExpandRow(row);
  }

  calculateRowHeight = (row: any) => {
    // Her bir satırın detayındaki öğelerin sayısına göre dinamik olarak yükseklik hesaplanıyor
    this.rows.forEach(p => {
      return p.locationSubCategoryDtos.length * 30
    }); // Her bir öğe için 30 piksel yükseklik
  }
}
