import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductTrasformDialogComponent } from 'app/dialogs/product-trasform-dialog/product-trasform-dialog.component';
import { ProductValue } from 'app/dtos/product/product-value';
import { rowData } from 'app/dtos/qr-reader-product/row-data';
import { _isId, _isUserName } from 'app/services/auth.service';
import { LocationSubCategoryService } from 'app/services/models/location-sub-category.service';
import { MovementService } from 'app/services/models/movement.service';
import { ProductService } from 'app/services/models/product.service';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeDevice } from 'ngx-scanner-qrcode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QrComponent implements OnInit, AfterViewInit {
  @ViewChildren('tdWidth') tdWidthList: QueryList<ElementRef>;
  @ViewChild('action', { static: false }) scanner?: NgxScannerQrcodeComponent;
  cameras: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined;

  constructor(private qrScannerService: NgxScannerQrcodeService,
    private ngbModal: NgbModal,
    private productService: ProductService,
    private locationSubCategoryService: LocationSubCategoryService,
    private movementService: MovementService,
    private toastService: ToastrService) { }

  ngAfterViewInit(): void {
    this.tdWidthList.forEach((tdWidth: ElementRef, index: number) => {
    });
  }



  readQR = '';
  locationSubCategory: any;
  filteredCount: number = 0;
  isProductionProduct :boolean = false;
  qrdata: any;
  getByQrData: any;
  async handleScanSuccess(event: any, modal) {
    this.readQR = '';
    if (event) {

      this.readQR = event[0].value
      if (this.readQR.length > 100) {
        this.scanner!.stop();
        this.qrdata = this.processData(event[0].value);
        this.filteredCount = this.qrdata.filter(p => p.uygunmu === false).length;

        this.getByQrData = await this.productService.getByQrProduct(this.qrdata[0].alt_limit, this.qrdata[1].alt_limit, this.qrdata[3].deger, this.qrdata[2].deger, this.qrdata[4].deger);
        if(this.getByQrData != null){
          this.isProductionProduct = this.getByQrData.movements?.some(p=>p.locationId == 3);
          if(this.isProductionProduct)
            this.toastService.error(this.getByQrData.movements[0].locationSubName + " konumundaki ürün taşınamaz.")
        }
      }
      else
        this.toastService.error("Yanlış bir QR okuttunuz.")
      this.scanner!.stop();


      const modalRef = this.ngbModal.open(modal, {
        centered: true,
        size: 'xl',
        windowClass: 'modal modal-success'
      });
      try {
        const result = await modalRef.result;
        if (result === 'Accept click') {
          const response = await this.productService.createProduct({
            userId: _isId,
            materialNo: this.qrdata[0].alt_limit,
            productColorCode: this.qrdata[1].alt_limit,
            batchNo: this.qrdata[2].deger,
            totNo: this.qrdata[3].deger,
            productionDate: this.qrdata[4].deger,
            viscositypackageFC325: this.qrdata[5].deger,
            viscositypackageFC325Min: this.qrdata[5].alt_limit,
            viscositypackageFC325Max: this.qrdata[5].ust_limit,
            resistivitypackage: this.qrdata[6].deger,
            resistivitypackageMin: this.qrdata[6].alt_limit,
            resistivitypackageMax: this.qrdata[6].ust_limit,
            solidsPackage: this.qrdata[7].deger,
            solidsPackageMin: this.qrdata[7].alt_limit,
            solidsPackageMax: this.qrdata[7].ust_limit,
            specificgravitypackage: this.qrdata[8].deger,
            specificgravitypackageMin: this.qrdata[8].alt_limit,
            specificgravitypackageMax: this.qrdata[8].ust_limit,
            startsagging1cmESTA: this.qrdata[9].deger,
            startsagging1cmESTAMin: this.qrdata[9].alt_limit,
            startsagging1cmESTAMax: this.qrdata[9].ust_limit,
            poppinglimitESTA: this.qrdata[10].deger,
            poppinglimitESTAMin: this.qrdata[10].alt_limit,
            poppinglimitESTAMax: this.qrdata[10].ust_limit,
            scribeTest: this.qrdata[11].deger,
            scribeTestMin: this.qrdata[11].alt_limit,
            scribeTestMax: this.qrdata[11].ust_limit,
            scribeTestRep: this.qrdata[12].deger,
            scribeTestRepMin: this.qrdata[12].alt_limit,
            scribeTestRepMax: this.qrdata[12].ust_limit,
            craterTest: this.qrdata[13].deger,
            craterTestMin: this.qrdata[13].alt_limit,
            craterTestMax: this.qrdata[13].ust_limit,
            cleannessTest: this.qrdata[14].deger,
            cleannessTestMin: this.qrdata[14].alt_limit,
            cleannessTestMax: this.qrdata[14].ust_limit,
            glossEsta: this.qrdata[15].deger,
            glossEstaMin: this.qrdata[15].alt_limit,
            glossEstaMax: this.qrdata[15].ust_limit,
            hidingPowerdry: this.qrdata[16].deger,
            hidingPowerdryMin: this.qrdata[16].alt_limit,
            hidingPowerdryMax: this.qrdata[16].ust_limit,
            stonechip2bar: this.qrdata[17].deger,
            stonechip2barMin: this.qrdata[17].alt_limit,
            stonechip2barMax: this.qrdata[17].ust_limit,
            solidspackage60min10530min140: this.qrdata[18].deger,
            solidspackage60min10530min140Min: this.qrdata[18].alt_limit,
            solidspackage60min10530min140Max: this.qrdata[18].ust_limit,
            stonechip: this.qrdata[19].deger,
            stonechipMin: this.qrdata[19].alt_limit,
            stonechipMax: this.qrdata[19].ust_limit,
            stonechipRephighbake: this.qrdata[20].deger,
            stonechipRephighbakeMin: this.qrdata[20].alt_limit,
            stonechipRephighbakeMax: this.qrdata[20].ust_limit,
            adhesionTestOEM: this.qrdata[21].deger,
            adhesionTestOEMMin: this.qrdata[21].alt_limit,
            adhesionTestOEMMax: this.qrdata[21].ust_limit,
            adhesiontestRephighbake: this.qrdata[22].deger,
            adhesiontestRephighbakeMin: this.qrdata[22].alt_limit,
            adhesiontestRephighbakeMax: this.qrdata[22].ust_limit,
            dryhidingpowerblackgrey: this.qrdata[23].deger,
            dryhidingpowerblackgreyMin: this.qrdata[23].alt_limit,
            dryhidingpowerblackgreyMax: this.qrdata[23].ust_limit,
            craterCount: this.qrdata[24].deger,
            craterCountMin: this.qrdata[24].alt_limit,
            craterCountMax: this.qrdata[24].ust_limit,
            dirtCount: this.qrdata[25].deger,
            dirtCountMin: this.qrdata[25].alt_limit,
            dirtCountMax: this.qrdata[25].ust_limit,
            isWrong:this.filteredCount == 0 ? false:true
          });

          if (response.status == true) {
            this.toastService.success(response.message);
          } else
            this.toastService.error(response.message)


        }
        if (result === 'ProductTransport') {
          modalRef.close();
          this.openTrasport()
        }
        if (result === 'Owner') {

          this.locationSubCategory = await this.locationSubCategoryService.getLocationSubCategory(null, "Person:" + _isUserName);

          if (this.locationSubCategory == null) {
            await this.locationSubCategoryService.createSubCategory(2, "Person:" + _isUserName);
          }
          this.locationSubCategory = await this.locationSubCategoryService.getLocationSubCategory(null, "Person:" + _isUserName);
          const result = await this.movementService.createMovemnt(this.getByQrData.id, this.locationSubCategory.id, _isId);
          if (result.status == true) {
            this.movementService.sendEvent(true);
            this.toastService.success(result.message);
          }
          else
            this.toastService.error(result.message);
        }
      } catch (error) {

      }

    }
  }
  async openTrasport() {
    const modalRefTransport = this.ngbModal.open(ProductTrasformDialogComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'modal modal-success',
    });
    modalRefTransport.componentInstance.data = this.getByQrData;
  }

  ngOnInit(): void {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      this.cameras = devices.filter(device => device.kind === 'videoinput');
      if (this.cameras.length > 0) {
        this.selectedDevice = this.cameras[0];  // Varsayılan olarak ilk kamerayı seç
      }
    });
  }
  selectCamera(): void {
    if (this.selectedDevice) {
    }
  }
  startScanner(device: MediaDeviceInfo): void {
    if (this.scanner!.isStart) {
      this.scanner.stop();
    }

    // const config: any = {
    //   video: { deviceId: device.deviceId }
    // };

    this.scanner!.playDevice(device.deviceId)
  }


  scannerOpen(): void {
    if (this.scanner!.isStart) {
      // Kamerayı durdur
      this.scanner!.stop();
    } else {
      // Kamerayı başlat
      if (this.selectedDevice) {
        this.startScanner(this.selectedDevice);

      } else {
        //  this.scanner!.start();
      }
    }
  }
  productValue: any;
  getProductValueProperties() {
    const productValue = new ProductValue();
    const properties = productValue.list// Özelliklerin adlarını alıyoruz

    return properties;
  }

  processData(data: string): rowData[] {
    // Kullanımı
    this.productValue = this.getProductValueProperties();

    const lines = data.split('\n').filter(line => line.trim() !== '');
    const processedData: rowData[] = [];

    for (let i = 0; i < lines.length; i++) {
      const columns = lines[i].split('\t').map(value => value.trim());

      const rowData: rowData = { alt_limit: columns[0] };

      if (columns.length > 1) {
        rowData.ust_limit = columns[1];
      }
      if (columns.length > 2) {
        rowData.deger = columns[2];
      }

      if (i >= 5) {
        rowData.uygunmu = false;
        const altLimitMatch = rowData.alt_limit.match(/^\s*([<>]=?)?\s*(\d+(\.\d+)?|\d+,\d+)$/);
        const ustLimitMatch = rowData.ust_limit.match(/^\s*([<>]=?)?\s*(\d+(\.\d+)?|\d+,\d+)$/);

        if (altLimitMatch && !isNaN(Number(altLimitMatch[2].replace(',', '.')))) {
          rowData.alt_limit = altLimitMatch[1];
          rowData.alt_limit = parseFloat(altLimitMatch[2].replace(',', '.')).toString();
        }

        if (ustLimitMatch && !isNaN(Number(ustLimitMatch[2].replace(',', '.')))) {
          rowData.ust_limit = ustLimitMatch[1];
          rowData.ust_limit = parseFloat(ustLimitMatch[2].replace(',', '.')).toString();
        }

        if (rowData.alt_limit === 'N/A' && rowData.ust_limit === 'N/A' && rowData.deger === 'N/A') {
          rowData.uygunmu = true;
        } else if (rowData.alt_limit === 'Uygun' && rowData.ust_limit === 'Uygun' && rowData.deger === 'Uygun') {
          rowData.uygunmu = true;
        } else if (rowData.alt_limit === 'N/A' || rowData.ust_limit === 'N/A') {
          rowData.uygunmu = true;
        }
        else if (!isNaN(Number(rowData.deger.replace(',', '.')))) {
          if (rowData.alt_limit && rowData.ust_limit) {
            const deger = parseFloat(rowData.deger.replace(',', '.'));
            const altLimit = parseFloat(rowData.alt_limit);
            const ustLimit = parseFloat(rowData.ust_limit);

            if (
              (rowData.alt_limit === '<' && deger < altLimit) ||
              (rowData.alt_limit === '<=' && deger <= altLimit) ||
              (rowData.ust_limit === '>' && deger > ustLimit) ||
              (rowData.ust_limit === '>=' && deger >= ustLimit) ||
              (deger >= altLimit && deger <= ustLimit)
            ) {
              rowData.uygunmu = true;
            }
          }
        }
      }

      processedData.push(rowData);
    }

    return processedData;
  }


}
