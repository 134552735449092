import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserAuthServiceService } from './models/user-auth.service';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private jwtHelper: JwtHelperService, private userAuthService: UserAuthServiceService,private router:Router) { }

  async identityCheck() {
    const token: string = localStorage.getItem("accessToken");
    let expired: boolean;
    let userId: number;
    let userName: string;
    let roles: string;
    let userNameAndLastName: string;
    const tokenDecode = this.jwtHelper.decodeToken(token);
    try {
      
      const expirationDate = this.jwtHelper.getTokenExpirationDate();
      const localExpirationDate = new Date(expirationDate.toString());
      localExpirationDate.setHours(localExpirationDate.getHours() - 3);
      const expir = localExpirationDate.toLocaleString() > new Date().toLocaleString() ? true : false;
      expired = expir;

      if (!expired) {
        const refreshToken = localStorage.getItem('refreshToken');
        if(refreshToken){
          await this.userAuthService.refrestTokenLogin(refreshToken, (state) => {
            if(state)
              expired = state;
            else{
              localStorage.removeItem("refreshToken")!;
              localStorage.removeItem("accessToken")!;
              this.router.navigate(['/pages/authentication/login-v2'])
              expired = false
            }
          })
        }else
          expired = false;
      }
        userId = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        userName = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        roles = tokenDecode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        userNameAndLastName = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
      } catch (error) {
        expired = true;
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
      _isAuthenticated = token != null && expired;
      _isId = userId;
      _isUserName = userName;
      _isRole = roles;
      _isUserNameAndLastName = userNameAndLastName;
    }

  get isAuthenticated(): boolean {
      return _isAuthenticated;
    }
  get isId(): number {
      return _isId;
    }
  get isUserName(): string {
      return _isUserName;
    }
  get isUserNameAndLastName(): string {
      return _isUserNameAndLastName;
    }
  get isRole(): string {
      return _isRole;
    }

  }

export let _isAuthenticated: boolean;
export let _isId: number;
export let _isUserName: string;
export let _isUserNameAndLastName: string;
export let _isRole: string;

