import { Component, Input, OnInit } from '@angular/core';
import { _isId, _isUserName } from 'app/services/auth.service';
import { MovementService } from 'app/services/models/movement.service';
import { ProductService } from 'app/services/models/product.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//import { EcommerceService } from 'app/main/apps/ecommerce/ecommerce.service';

@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  // Public
  @Input() movementType: boolean;

  public products = [];
  public cartList = [];
  public cartListLength;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {EcommerceService} _ecommerceService
   */
  constructor(private movementService: MovementService) {
    this._unsubscribeAll = new Subject();
  }



  async ngOnInit() {

    if (_isId) {
      this.movementService.event$.subscribe(async result => {

        const card = await this.movementService.getBasketProduct("Person:" + _isUserName, _isId);
        this.cartListLength = card.length;
   
        this.products = card;
      });
      const card = await this.movementService.getBasketProduct("Person:" + _isUserName, _isId);
      this.cartListLength = card.length;

      this.products = card;

    }

    // Get Products
    /* this._ecommerceService.getProducts();
 
     // Get Cart List
     this._ecommerceService.getCartList();
 
     // Subscribe to Cart List
     this._ecommerceService.onCartListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
       this.cartList = res;
       this.cartListLength = this.cartList.length;
     });
 
     // Subscribe to ProductList change
     this._ecommerceService.onProductListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
       this.products = res;
 *
       if (this.products.length) {
         // update product is in CartList : Boolean
         this.products.forEach(product => {
           product.isInCart = this.cartList.findIndex(p => p.productId === product.id) > -1;
         });
       }
     });
  */
  }
}
