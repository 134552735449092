import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationEndpointService {

  constructor(private httpClientsService:HttpClientService) { }
  
  async getRolesToEndpoint(menu:string,code:string){
    const observable:Observable<any> = this.httpClientsService.post({
      controller:ControllerType.AuthorizationEndpoint,
      action:"GetRolesToEndpoint"
    },{
      menu:menu,
      code:code
    });
    return await firstValueFrom(observable);
  }
  async assignRoleToEndpoint(role:string[],menu,code,successCallBack?:()=>void,errorCallBack?:()=>void){
    const observable:Observable<any> = this.httpClientsService.post({
      controller:ControllerType.AuthorizationEndpoint,
    },{
      roles:role,
      menu:menu,
      code:code
    });
    const promiseData = firstValueFrom(observable);
    promiseData.then(
      successCallBack).catch(errorCallBack);

    return await promiseData;
  }

}
