import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './role.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'app/enum/role';


const routes: Routes = [
  {
    path: 'role',
    component: RoleComponent,
    canActivate:[AuthGuard],
    data: { roles:[Role.Admin],animation: 'auth' },
  },
];

@NgModule({
  declarations: [
    RoleComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    TranslateModule,
    NgbModule
  ]
})
export class RoleModule { }
