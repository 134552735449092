import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { AuthenticationModule } from './authentication/authentication.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { HomeModule } from './home/home.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    AuthenticationModule,
    MiscellaneousModule,
    HomeModule
  ],

  providers: []
})
export class PagesModule {}
