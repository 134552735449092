import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreTranslationService } from '@core/services/translation.service';

import { ApplicationService } from 'app/services/models/application.service';
import { RoleService } from 'app/services/models/role.service';


import { locale as en, locale } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationEndpointService } from 'app/services/models/authorization-endpoint.service';
import { GetRolesToEnpoint } from 'app/dtos/Role/role-endpoint-get';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleComponent implements OnInit {

  constructor(
    private roleService: RoleService,
    private applicationService: ApplicationService,
    private authorizationEndpointService:AuthorizationEndpointService,
    private _coreTranslationService: CoreTranslationService,
    private toastService:ToastrService,
    private modalService: NgbModal
  ) {
    this._coreTranslationService.translate(en, fr, de, pt);
  }

  public getAllEndpoint = [];
 

  async ngOnInit() {
    await this.getAllRoles();
    await this.getAuthorizeDefinitionEndoints();
  }

  async getAuthorizeDefinitionEndoints() {
    const data = await this.applicationService.GetAuthorizeDefinitionEndoints();
    const sortedDataList = data.sort(this.sortByActionLength);
    this.getAllEndpoint = sortedDataList;
  }

  sortByActionLength(a, b) {
    const lengthA = a.actions.length;
    const lengthB = b.actions.length;
    return lengthB - lengthA; // Büyükten küçüğe sırala
  }

  translate(name: string, definition: string): string {
    definition = definition?.replace(/\s+/g, '');
    return this.translateDefinition(name, definition);
  }
  translateDefinition(name, definition) {
    const localeData = locale.data.Definition;

    if (localeData[name] && localeData[name][definition]) {
      return localeData[name][definition];
    }
    return definition;
  }

  data: any;
  menu;
  code;
  getRolesToEndpoint:any;
  selected:boolean=false;
  async addEvent(modalbasic, menu, code) {
    this.getRolesToEndpoint = await this.authorizationEndpointService.getRolesToEndpoint(menu,code); 
    this.menu = menu;
    this.code = code;
    this.prepareData();
    
    const modalRef = this.modalService.open(modalbasic);
    try {
      const result = await modalRef.result;
      if (result === 'Success') {
        const checkedItems = this.data.filter(action => action.checked).map(action => action.name);
      
        if(checkedItems != null){
        await this.authorizationEndpointService.assignRoleToEndpoint(checkedItems,menu,code,
            ()=>this.toastService.success("Başarılı bir şekilde Role eklenmiştir"),()=> this.toastService.info("Rol eklenirken bir sorun oluştu"));    
        }
      }
    } catch (error) {
   
    }

  }
  async prepareData() {
    // Tüm rolleri al
    await this.getAllRoles();
  
    if (this.getRolesToEndpoint && this.getRolesToEndpoint.length > 0) {
      // Her rol için checked değerini belirle
      this.data.forEach((role: GetRolesToEnpoint) => {
        role.checked = this.getRolesToEndpoint.includes(role.name);
      });
    } else {
      // getRolesToEndpoint null veya boşsa, tüm rollerin checked değerini false yap
      this.data.forEach((role: GetRolesToEnpoint) => {
        role.checked = false;
      });
    }
  }

  toggleCheckbox(action) {
    action.checked = !action.checked; // Checkbox durumunu değiştir
    if (action.checked) {

    }
  }

  async getAllRoles() {
    this.data = await this.roleService.getAllRoles();

  }

}
