import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private httpClientsService:HttpClientService) { }

  async GetAuthorizeDefinitionEndoints(){
    const observable:Observable<any> = this.httpClientsService.get({
      controller:ControllerType.ApplicationService
    });
    return await firstValueFrom(observable);
  }
}
