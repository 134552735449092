import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { WinService } from 'app/services/models/win.service';

@Component({
  selector: 'app-win',
  templateUrl: './win.component.html',
  styleUrls: ['./win.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class WinComponent implements OnInit {

  constructor(private winService:WinService) { }

  rows = [];
  public pageBasic = 1;
  public basicSelectedOption: number = 5;
  public selectedSize = 5;
  public ColumnMode = ColumnMode;
  totalCount: Number = 0;
  public searchValue = '';
  ngOnInit(): void {
    this.getAllWin(this.pageBasic - 1,this.basicSelectedOption,this.searchValue);
  }

  async getAllWin(page,size,searchValue){
    const data =  await this.winService.getAllWin(page,size,searchValue);
    this.rows=data['wins'];
    this.totalCount=data['count'];
  }
  filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    this.searchValue = val;
    setTimeout(async () => {

      await this.getAllWin(this.pageBasic - 1, this.selectedSize,this.searchValue);
    }, 2000);
  }
  async onOptionChange() {

    this.selectedSize = Number(this.basicSelectedOption)
    await this.getAllWin(this.pageBasic - 1, this.selectedSize,this.searchValue)
  }

  async onPageChange(pageNumber: number) {
    await this.getAllWin(this.pageBasic - 1, this.selectedSize,this.searchValue);
  }

}
