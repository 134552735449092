import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService, _isAuthenticated, _isRole } from 'app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private jwtHelper: JwtHelperService, private router: Router, private _toastService: ToastrService,private authServic:AuthService) {
    //authServic.identityCheck()
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    this.authServic.identityCheck();

    if (!_isAuthenticated) {
      
      this.router.navigate(["pages/authentication/login-v2"], { queryParams: { returnUrl: state.url } });
      this._toastService.error("Giriş yapmadan erişilemez.", "Giriş Yap!");
      return false;
    } else if (route.data.roles && route.data.roles.indexOf(_isRole) === -1) {
      this.router.navigate(['/pages/miscellaneous/error']);
      this._toastService.error("Yetkisiz erişim.", "Yetkiniz Yok!");
      return false;
    }
   
    return true;
  }

}
