import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClientService:HttpClientService) { }

  async registerUser(userName:string,userNameAndLastName:string,password){
    const observable:Observable<any> = this.httpClientService.post({
      controller:ControllerType.User
    },{
      userName:userName,
      userNameAndLastName:userNameAndLastName,
      password:password,
      passwordConfirm:password
    })

    return await firstValueFrom(observable);
  }

  async updateUser(id,userName:string,userNameAndLastName:string,password:string){
    const observable:Observable<any> = this.httpClientService.put({
      controller:ControllerType.User,
      action:"UpdateUser"
    },{
      id:id,
      userName:userName,
      userNameAndLastName:userNameAndLastName,
      password:password??'',
    })

    return await firstValueFrom(observable);
  }

  async getAllUsers(seacrh,page,size){
    const observable:Observable<any> = this.httpClientService.get({
      controller:ControllerType.User,
      queryString:`search=${seacrh}&Page=${page}&Size=${size}`
    });
    return await firstValueFrom(observable);
  }

  async assignRoleToUser(id:string,roles:string[],successCallBack?:()=>void,errorCallBack?:(error)=>void){
    const observable:Observable<any> = this.httpClientService.post({
      controller:ControllerType.User,
      action:"assign-role-to-user"
    },{
      userId:id,
      roles:roles,
    });
   return await firstValueFrom(observable);
  }

  private eventSubject = new Subject<boolean>();
  
  event$ = this.eventSubject.asObservable();

  sendEvent(result: boolean) {
    this.eventSubject.next(result);
  }

}
