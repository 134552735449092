import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreSidebarModule } from '@core/components';
import { ProductListComponent } from './product-list/product-list.component';
import { CsvModule } from '@ctrl/ngx-csv';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { BlockUIModule } from 'ng-block-ui';
import { Role } from 'app/enum/role';



const routes: Routes = [
  {
    path: 'product',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Supervisor], animation: 'auth' },
  },
  {
    path: 'product/:search',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Supervisor], animation: 'auth' },
  }
];


@NgModule({
  declarations: [
    ProductComponent,
    ProductListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgxDatatableModule,
    CoreSidebarModule,
    CsvModule,
    CardSnippetModule,
    BlockUIModule.forRoot()
   
  ]
})
export class ProductModule { }
