<section id="ngx-datatable">
  <div class="row">
    <div class="col-md-6 col-12">

      

      <div class="d-flex justify-content-between align-items-center m-1">
        <label class="d-flex align-items-center">Gösterilecek satır
          <select class="form-control mx-25" [(ngModel)]="basicSelectedOption" (ngModelChange)="onOptionChange()">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          sayısı
        </label>
      </div>

    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <a class="btn btn-outline-secondary float-right mr-1" (click)="exportAsExcelFile()">
          <i data-feather="download" class="mr-25"></i>
        </a>
      </div>
    
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <label class="d-flex align-items-center"> <i data-feather="search" class="mr-25"></i><input type="search" placeholder="Ara" class="form-control ml-25"
            (keyup)="filterUpdate($event)" /></label>
      </div>
    </div>

  </div>
  <!-- Tablo devamı -->
  <!-- Tablo devamı -->
  <ngx-datatable #tableRowDetails  class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="40"
    [footerHeight]="50" [rowHeight]="58" [limit]="basicSelectedOption" [rows]="rows" [scrollbarH]="true">
    <!-- Row Detail Template -->
    <ngx-datatable-row-detail [rowHeight]="calculateRowHeight">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div class="ml-75 pl-5 pt-75">
          <div *ngFor="let item of row.locationSubCategoryDtos; let i = index">

            <table class="table">
              <tbody style="background-color: #f9f5f5;">
                <tr>
                  <th>
                    <a href="#/admin/product/{{item.name}}"> {{ item.name }}</a>
                  </th>
                  <th style="color: black;">
                    {{ item.movementCount }}
                  </th>
                  <th>
                    <a style="margin-right: 1%;" (click)="updateLocationSubCategory(row.id, item, modalForm)">
                      <i data-feather="edit" class="text-primary cursor-pointer"></i>
                    </a>
                    <a (click)="removeLocationSubCategory(item.id, modalBasic)" *ngIf="item.id != 1">
                      <i data-feather="trash-2" class="text-primary cursor-pointer"></i>
                    </a>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
      [canAutoResize]="false">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row"
          (click)="rowDetailsToggleExpand(row)">
        </a>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="#" prop="id"></ngx-datatable-column> -->
    <ngx-datatable-column name="Yer" prop="name"></ngx-datatable-column>
    <ngx-datatable-column name="Toplam Ürün" prop="categoryCount"></ngx-datatable-column>
    <ngx-datatable-column [width]="120" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="d-flex align-items-center">
          <div ngbDropdown container="body">
            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
              <a (click)="updateLocationCategory(row,modalForm)" ngbDropdownItem class="d-flex align-items-center"><i
                  data-feather="file-text" class="mr-50"></i>Güncelle</a>
              <a ngbDropdownItem class="d-flex align-items-center"  *ngIf="row.id != 1 && row.id != 2 && row.id != 3"
                (click)="removeLocationCategory(row.id,modalBasic)"><i data-feather="trash-2" class="mr-50"></i> Sil</a>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template>
        <div class="datatable-footer-inner">
          <div class="page-count">
            Toplam Veri {{ totalCount }}
          </div>


          <ngb-pagination [collectionSize]="(totalCount / basicSelectedOption) * 10" [(page)]="pageBasic"
            (pageChange)="onPageChange($event)">
            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18"
                [class]="'font-weight-bolder'"></span>
            </ng-template>
            <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18"
                [class]="'font-weight-bolder'"></span></ng-template>
          </ngb-pagination>
        </div>

      </ng-template>

    </ngx-datatable-footer>

  </ngx-datatable>


</section>

<ng-template #modalBasic let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Kategori Sil!</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <p>
      Konumu Silmek istediğine eminmisin?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
      İptal
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Success')" rippleEffect>
      Sil
    </button>
  </div>
</ng-template>

<ng-template #modalForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Alt Konum Güncelleme</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form action="#">
      <label>Konum Adı</label>
      <div class="form-group">
        <input type="text" id="konumAdi" name="locationCategory" [(ngModel)]="locationCategory"
          placeholder="Güncellemek istediğiniz konum adını giriniz." class="form-control" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Cancel')" rippleEffect>
      İptal
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Success')" rippleEffect>
      Güncelle
    </button>
  </div>
</ng-template>