<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <div class="row">
        <div class="col-12">  
          <app-content-header [contentHeader]="contentHeader"></app-content-header>
          
          <div class="card">
            <div class="col-12">
              <button class="btn btn-icon btn-primary" style="margin: 1%;" type="button" rippleEffect (click)="toggleSidebarSubCategory('app-right-location-category')">
                <i data-feather="plus" class="mr-25"></i>
                <span>Ana Konum Ekle</span>
              </button>

              <button class="btn btn-icon btn-success" style="margin: 1%;" type="button" rippleEffect (click)="toggleSidebarCategory('app-right-location-category')">
                <i data-feather="plus" class="mr-25"></i>
                <span>Alt Konum Ekle</span>
              </button>
           
            </div>
            <div class="card-body">
              <app-list-location-category></app-list-location-category>

              <core-sidebar
          class="modal modal-slide-in sidebar-todo-modal fade"
          name="app-right-location-category"
          overlayClass="modal-backdrop"
          hideOnEsc="true"
        >
          <app-right-location-category [categoryType]="isSubCategory"></app-right-location-category>
        </core-sidebar>
            </div>
           
           
          </div>
        </div>
      </div>
    </div>
  </div>