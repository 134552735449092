import { Injectable } from '@angular/core';
import { ControllerType, HttpClientService } from '../http-client.service';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClientService:HttpClientService) { }

  async getAllRoles(){
    const observable:Observable<any>=this.httpClientService.get({
      controller:ControllerType.Role
    });
    return await firstValueFrom(observable);
  }

}
