import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, delay, from, of, switchMap } from 'rxjs';


import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserAuthServiceService } from './models/user-auth.service';



@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerInterceptorService implements HttpInterceptor {

  constructor(private toastrService: ToastrService, private authService: UserAuthServiceService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(catchError(error => {
      switch (error.status) {
        case HttpStatusCode.Unauthorized:
          this.toastrService.error("Erişmeye yetkiniz yok.", "Erişim hatası.");
          break;
        case HttpStatusCode.InternalServerError:
          this.toastrService.error("Sunucu erişilmiyor.", "Sunucu hatası.")
          break;
        case HttpStatusCode.NoContent:
          this.toastrService.error("İçerik boş olamaz.", "Sunucu hatası.")
          break;
        case HttpStatusCode.BadRequest:
          this.toastrService.error("Geçersiz istek yapıldı.", "Geçersiz İstek.")
          break;
        case HttpStatusCode.NotFound:
          this.toastrService.error("Sayfa bulunamadı!.", "Sayfa Erişimi.")
          break;
        default:
          this.toastrService.error("Beklenmeyen bir hata meydana gelmiştir!.", "Hata!")
          break;
      }

      return of(error);
    }));
  }
}
